.cart-container {
  padding: 20px;
  max-width: 1200px;
  max-height: auto;
  margin: auto;
  overflow-y: auto;
}

.price-details {
  max-width: 375px;
  height: 275px;
  border-radius: 16px;
  border: 1px solid #d6d5d5;
  margin-left: 8%;
}

.paymentButton {
  width: 100%;
  background-color: #ed1c25;
  color: #fff;
  border-radius: 8px;
  height: 45px;
  font-size: 16px;
  font-weight: 600; 

}

.cart-item-wrapper {
  border: 1px solid #d6d5d5;
  border-radius: 16px;

}

.cart-item {
  display: flex;
  align-items: center;
  align-items: flex-start;
  border-bottom: 1px solid #d6d5d5;
  padding: 20px;
}

.cart-item button {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2199999988079071px;
  text-transform: uppercase;
}

.cart-item button:hover {
  color: #ed1c25;
}

.cart-item h5 {
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2199999988079071px;
  text-transform: capitalize;
}

.cart-item p {
  margin: 0 0 15px 0;
}

.cart-item-img {
  flex: 0 0 145px;
  height: 145px;
}

.cart-item-img img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.cart-item-detail {
  flex: 1;
  padding: 5px 20px;
}

.cart-item-detail h5 {
  margin: 0;
}

.cart-item-time {
  color: #888;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.cart-item-price {
  font-size: 18px;
  font-weight: 600;
  line-height: 13px;

}

.cart-item-date {
  flex: 0 0 200px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.2199999988079071px;
  text-align: right;

}

.booking-item {
  display: flex;
  /* align-items: center; */
  border-radius: 16px;
  /* border: 1px solid #d6d5d5; */
  height: 120px;
  margin-bottom: 20px;
}

.booking-item img {}

.total {
  font-size: 20px;
  font-weight: bold;
}

.price-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-code {
  margin: 20px 0;
  position: relative;
  display: inline-block;
  margin: auto;
}

.signIn {
  margin: 20px 0;
  position: relative;
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.promo-label {
  padding: 10px 5px;
  margin: 0 25px;
  pointer-events: none;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: 0.2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
  background: #fff;
}

.Gst {
  color: #9c9c9c;
  font-weight: 400;
  margin-bottom: 0;
}

.total {
  color: #322d2d;
  font-weight: 600;
  font-size: 18px;
  padding: 20px;
}

.price-cal {
  width: 280px;
}

.Coupon-code {
  width: 280px;
  padding: 10px;
  margin: auto;
  border: 1px solid #D6D5D5;
  border-radius: 6px;
  margin-bottom: 12px;
}


.SignInInput {
  width: 100%;
  padding: 10px;
  margin: auto;
  border: 1px solid #D6D5D5;
  border-radius: 6px;
  margin-bottom: 12px;
}

input:focus+label,
input:not(:placeholder-shown)+label {
  opacity: 1;
  transform: scale(0.75) translateY(-70%) translateX(-14px);
}

#create-account-button {
  background-color: #ED1C25;
  white-space: nowrap;
}

.forgot-password-box {
  padding: 7.5rem;
}


@media (min-width: 1600px) {
  .SignInInput {
    padding: 10px;
    margin: auto;
    border: 1px solid #D6D5D5;
    border-radius: 6px;
    margin-bottom: 12px;
  }
}


@media (max-width: 769px) {
  .md\:w-1\/2 {
    width: 100%;
  }

  .SignUp-image {
    display: none;
  }

  .forgot-password-box {
    padding-top: 7rem;
  }

  .cart-item {
    flex-direction: column;
  }

  .cart-item-detail {
    flex: auto;
    padding: 10px 0;
  }

  .cart-item-date {
    flex: auto;
  }
}

@media (max-width: 426px) {
  .cart-container {
    display: block;
  }

  .price-details {
    max-width: 382px;
    height: 275px;
    border-radius: 16px;
    border: 1px solid #d6d5d5;
    margin-left: 0;
  }

  .paymentButton {
    background-color: #ed1c25;
    color: #fff;
    border-radius: 8px;
  }

  .SignInInput {
    padding: 10px;
    margin: auto;
    border: 1px solid #D6D5D5;
    border-radius: 6px;
    margin-bottom: 12px;
  }

  .signup-form {
    padding: 0px;
  }

  .forgot-password-box {
    padding-top: 7rem;
  }
}

@media (max-width: 376px) {
  .price-details {
    max-width: 382px;
    height: 275px;
    border-radius: 16px;
    border: 1px solid #d6d5d5;
    margin-left: 0;
  }

  .SignInInput {
    padding: 10px;
    margin: auto;
    border: 1px solid #D6D5D5;
    border-radius: 6px;
    margin-bottom: 12px;
  }

  .paymentButton {
    background-color: #ed1c25;
    color: #fff;
    border-radius: 8px;
  }

  .create-account-button {
    width: 67%;
  }

  button.inline-flex.w-50.items-center.justify-center.rounded-md.bg-black.px-3\.5.py-2\.5.font-semibold.leading-7.text-white.hover\:bg-black\/80.create-account-buttoncursor-not-allowed.opacity-50 {
    width: 67% !important;
  }
}

@media (max-width: 321px) {
  .SignInInput {
    padding: 10px;
    margin: auto;
    border: 1px solid #D6D5D5;
    border-radius: 6px;
    margin-bottom: 12px;
  }

  .create-account {
    font-size: small;
  }

  button.inline-flex.w-50.items-center.justify-center.rounded-md.bg-black.px-3\.5.py-2\.5.font-semibold.leading-7.text-white.hover\:bg-black\/80.create-account-buttoncursor-not-allowed.opacity-50 {
    width: 72% !important;
  }
}