.MuiSwitch-track {
  background-color: red !important;
}
/* CSS */
.order-box {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0 9px 9px rgba(19, 19, 19, 0.1);
}

.booking-item-box {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.booking-item {
  display: flex;
  align-items: center;
}

.booking-item img {
  width: 100px;
  height: 100%;
  margin-right: 20px;
}

.booking-item .ml-4 {
  width: calc(100% - 120px); /* Adjust the width accordingly */
}

.booking-item .text-black {
  font-size: 16px;
  font-weight: bold;
}

.booking-item .text-gray-400 {
  font-size: 14px;
}

/* Add more styles as needed */
