.deleteButton {
  display: flex;
  justify-content: flex-end;
  /* height: auto; */
  /* width: 10px; */
  /* height: 10px; */
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}



.AddCardButton {
  display: flex;
  justify-content: flex-end;
}

.flex-grow-70 {
  flex-grow: 0.8;
}

.week-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.card-index {
  position: absolute;
  width: 213px;
  z-index: 99;
  top: 3rem;
  /* left: -1rem; */
}

.week-selector button {
  padding: 5px 10px;
}

.bookingButton {
  background-color: #ed1c25;
  border-radius: 8px;
}

.week-selector h2 {
  margin: 0;
}

.headingBoxStyle {
  width: 150px;
  height: 40px;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-color: #ed1c25;
  color: #ed1c25;
}

.pitch-box-style {
  width: 150px;
  height: 40px;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-color: #48c14c;
  color: #48c14c;
  /* padding-left: 1%;
      padding-right: 1%; */
}

.pitch-week-box-style {
  margin: 2px;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  width: calc(100% / 3 - 12px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-color: #48c14c;
  color: #48c14c;
}

.download-invoice {
  margin-bottom: 2rem;
  width: 375px;
  border-radius: 8px;
  background-color: #ed1c25;
  color: #fff;
}

.payment-successfull-page {
  width: 100%;
  height: 70vh;
}

.payment-successfull-box {
  width: 455px;
  height: 368px;
  border-radius: 16px;
  border-width: 1px;
  border: 1px solid #d6d5d5;
}

.boxStyleWeeks {
  width: 150px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.timings {
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  color: black;
}

.pitch-name-week {
  font-size: x-small;
}

.timings-week {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 1px;
  color: black;
  height: 140.6px;
}

.nav-links span {
  cursor: pointer;
}

.nav-links span:hover {
  color: #e82026;
}

.signedInNav {
  display: none;
}

.nav-logo {
  cursor: pointer;
}

.app-container {
  display: flex;
  height: 100%;
  width: 100%;
}


.signup-form-container {
  flex: 4;
  /* 40% of the available space */
  display: flex;
  flex-direction: column;
  justify-content: top;
  /* Vertically center the form */
}

.signup-form {
  width: 80%;
  /* Adjust width as needed */
  max-width: 400px;
  /* Limit maximum width */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.image-container {
  flex: 6;
  /* 60% of the available space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
}

.image-container img {
  width: 100%;
  object-fit: cover;
}

.googleSignup {
  background: #F8F8F8;
  color: #9C9C9C;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  letter-spacing: 0.05em;
  text-align: left;

}
.verify_btn{padding: 11px;}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}

@media (max-width: 769px) {
  .signup-form-container {
    padding: 20px;
  }

  .form-body {
    padding: 0;
  }

  .image-container {
    display: none;
  }
}

@media (max-width: 426px) {
  .searchbarFilter {
    display: flex;
    flex-direction: column;
    margin-left: 45px;
  }

  .nearMeSports {
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .searchBar {
    width: 93%;
  }

  .venueSearch {
    padding-right: 0px;
    font-size: 12px;
    padding-left: 0;
  }

  .activitySearch {
    padding-left: 0;
    font-size: 12px;
    border: none;
  }

  .searchButton {
    display: none;
  }

  .searchBarContent {
    padding: 0;
  }

  .newsLetter {
    display: block;
  }

  .newsletterSubmit {
    padding-left: 5%;
    padding-right: 0%;
  }

  .header-section {
    padding: 1rem;
  }

  .venueDetailsHeading {
    margin-left: 0.5rem;
  }

  .bookingButton {
    margin-right: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: small;
    font-weight: 400;
  }

  .payment-successfull-page {
    width: 100%;
    height: 50vh;
  }

  .payment-successfull-box {
    width: 387px;
    height: 356px;
    border-radius: 16px;
    border-width: 1px;
  }

  .download-invoice {
    margin-bottom: 1rem;
    width: 317px;
    border-radius: 8px;
  }

  .pitches {
    overflow: auto;
  }

  .timing {
    width: 80px;
  }

  .pitch-day {
    margin-left: 1rem;
  }

  .pitch-box-style {
    width: 71px;
    height: 40px;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-color: #48c14c;
    color: #48c14c;
  }

  .headingBoxStyle {
    width: 71px;
    height: 40px;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-color: #ed1c25;
    color: #ed1c25;
  }

  .timings {
    width: 90px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 5px 11px;
    color: black;
  }

  .data-picker {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .data-picker-week {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .pitch-name-address {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .pitch-day {
    margin-left: 1rem;
  }

  .booking-for {
    margin-left: 1rem;
  }

  .view-cart-button {
    margin-right: 2px;
  }

  .pitches-shown {
    margin-left: 1rem;
  }

  .week-range {
    font-size: x-small;
  }


  .date-tabs {
    margin-top: .5rem;
  }

  .week-calender {
    margin-left: 1rem;
    overflow: auto;
  }

  .time-col-week {
    width: 42px;
  }

  .timings-week {
    width: 51px;
    height: 140.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px 5px 0px;
    color: black;
    font-size: x-small;
  }

  .venue-info {
    margin-left: 1rem;
    margin-top: .2rem;
  }

  .venue-contact {
    margin-top: 2rem;
    margin-left: 1rem;
  }

  .venue-details {
    font-size: 14px;
  }

  .venue-available-for {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .available-venue-spec {
    font-size: small;
  }

  .ant-col.p-4.venue-cards.ant-col-xs-12.ant-col-sm-6.css-dev-only-do-not-override-usln0u {
    padding: 0.5rem !important;
  }


}

@media (max-width: 376px) {
  .navbar {
    width: auto;
  }

  .activitySearch {
    font-size: 9px;
  }

  .venueSearch {
    font-size: 9px;
  }

  .Footer-news {
    width: 16rem;
  }

  .price-details {
    max-width: 382px;
    height: 275px;
    border-radius: 16px;
    border: 1px solid #d6d5d5;
    margin-left: 0;
  }

  .paymentButton {
    width: 246px;
    background-color: #ed1c25;
    color: #fff;
    border-radius: 8px;
  }

  .download-invoice {
    margin-bottom: 1rem;
    width: 272px;
    border-radius: 8px;
  }

  .payment-successfull-box {
    width: 343px;
    height: 340px;
    border-radius: 16px;
    border-width: 1px;
  }

  .payment-successfull-page {
    width: 100%;
    height: 48vh;
  }

  button.bg-gray-500.hover\:bg-gray-700.text-white.font-bold.py-2.px-4.sm\:px-2.rounded.mr-20.view-cart-button {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .date-tabs {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 320px) {
  .navbar {
    width: auto;
  }
}